var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm brand từ nhà cung cấp",
        visible: _vm.dialogVisible,
        width: "45%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "newBrand",
          attrs: {
            "label-width": "140px",
            model: _vm.newBrand,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Chọn nhà cung cấp", prop: "idSupplier" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-grade",
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "", placeholder: "Chọn nhà cung cấp" },
                  model: {
                    value: _vm.newBrand.idSupplier,
                    callback: function ($$v) {
                      _vm.$set(_vm.newBrand, "idSupplier", $$v)
                    },
                    expression: "newBrand.idSupplier",
                  },
                },
                _vm._l(_vm.supplierList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.supplierName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tên brand", prop: "brandName" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập tên brand",
                },
                model: {
                  value: _vm.newBrand.brandName,
                  callback: function ($$v) {
                    _vm.$set(_vm.newBrand, "brandName", $$v)
                  },
                  expression: "newBrand.brandName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Loại brand", prop: "brandtype" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { label: true },
                  model: {
                    value: _vm.newBrand.brandTypeCskh,
                    callback: function ($$v) {
                      _vm.$set(_vm.newBrand, "brandTypeCskh", $$v)
                    },
                    expression: "newBrand.brandTypeCskh",
                  },
                },
                [_vm._v("CSKH")]
              ),
              _c(
                "el-checkbox",
                {
                  attrs: { label: true },
                  model: {
                    value: _vm.newBrand.brandTypeAds,
                    callback: function ($$v) {
                      _vm.$set(_vm.newBrand, "brandTypeAds", $$v)
                    },
                    expression: "newBrand.brandTypeAds",
                  },
                },
                [_vm._v("Quảng cáo")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú", prop: "supplierNote" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 2,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập ghi chú",
                },
                model: {
                  value: _vm.newBrand.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.newBrand, "note", $$v)
                  },
                  expression: "newBrand.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("newBrand")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "medium", mini: "" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("newBrand")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }